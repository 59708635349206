import React, {PureComponent} from 'react';

import {Helmet} from "react-helmet";

import {defaultTexts} from "../constants/defaultTexts";
const logo = 'http://173.249.20.192:3551/files/default-empty-logo.png';

class MetaTags extends PureComponent {

    render() {
        const {title, description, image, url} = this.props;
        const _title = title ? (`${title} | ${defaultTexts.title}`) : defaultTexts.title;
        const _description = description || defaultTexts.description;
        let _image = logo;
        if (image) {
            _image = image;
        }
        const _url = url || window.location.href;

        return (<Helmet>
                <title>{_title}</title>
                <meta name="description" content={_description}/>
                <meta property="og:image" content={_image}/>

                <meta property="og:title" content={_title}/>
                <meta property="og:site_name" content='http://danksystems.am'/>
                <meta property="og:description" content={_description}/>

                <meta property="og:url" content={_url}/>
                <meta property="og:image:width" content={600}/>
                <meta property="og:image:height" content={600}/>
                <meta property="og:type" content={'website'}/>

            </Helmet>
        )
    }
}

export default MetaTags;
//  {newImage &&  <meta property="og:image" content={newImage}/>}
