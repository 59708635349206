// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "../assets/styles/components/sponsors.scss";
import {SliderNextButton, SliderPrevButton} from "./uiElements/buttons";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {MoreArrIcon} from "../assets/images";
import {useRef, useState} from "react";

//Import Components

// Import utils

function Sponsors (props) {
    const {staticTexts, sponsors} = props;
    const [slideIndex, setSlideIndex] = useState(0);

    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (sponsors?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };

    let sliderRef = useRef(null);
        const settings = {
            dots: false,
            arrows: false,
            autoplay:true,
            infinite:true,
            speed: 2000,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode:  window.innerWidth < 700,
            centerPadding: '60px 0 0',
            ref: sponsors => (sliderRef = sponsors),
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        rows:3,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 2,
                        rows:3,
                    }
                },
            ]
        };

        return !!sponsors?.length && <div className="sponsors-wrapper">
            <div className={'more-info'}>
                <span>{staticTexts?.homepage_sponsors_title}</span>
            </div>
            <div className="sponsors-title">{staticTexts?.homepage_sponsors_block_title}</div>

            {
                window.innerWidth < 1050 && <div className={'sponsors-responsive-wrapper'}>
                    {
                        sponsors?.length && sponsors.map(item =>{
                            return <div className="sponsors-item" key={item.id}>
                                    <div className="sponsors-content">
                                        <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                             title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                             alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                    </div>
                                </div>
                        })
                    }
                </div>
            }
            {
                window.innerWidth > 1050 &&  <div className="sponsors-list-wrapper">
                    {
                        <Slider {...settings}>
                            {sponsors?.map(item => {
                                return <div  key={item}>
                                    <div className="sponsors-item" key={item.id}>
                                        <div className="sponsors-content">
                                            <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                                 title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                                 alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </Slider>
                    }
                    {!!sponsors?.length < 4 && <>
                        <SliderPrevButton  cbPrev={() => prevSlide(slideIndex)}/>
                        <SliderNextButton  cbNext={() => nextSlide(slideIndex)}/>
                    </>}
                </div>
            }
        </div>
}

export default Sponsors;
