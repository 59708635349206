// import packages
import React, {useState} from "react";
import {connect} from "react-redux";

//Import styles
import "../../assets/styles/homepage/project.scss";

//Import Components
import { Tabs } from 'antd';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {LinkArr, MoreArrIcon} from "../../assets/images";
import {CALENDAR_LINK} from "../../constants/constType";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";

function Project(props){
    const {services,staticTexts} = props
    const [tabPosition, setTabPosition] = useState('right');

    return <div className={'description-wrapper'}>
        <div className="description-content">
            <div className={'text-content'}>
                <p>{staticTexts?.homepage_projects_subtitle} </p>
                <div className="title">{staticTexts?.homepage_projects_title}</div>
                <div className="text">{staticTexts?.homepage_projects_description}</div>
            </div>
        </div>

        <div className={'projects-block'}>
            <Tabs
                tabPosition={tabPosition}
                items={services?.map((item,index) => {
                    return {
                        label: <div className={'project'}>
                            <div className={'project-title'}>
                                {item?.title}
                            </div>
                            <div className={'project-description'}>
                                {item?.description}
                            </div>
                                <Link to={getUrlWithLocal(`/service/${item?.slug}`)}  className={'project-more-news'}>
                                    {staticTexts?.homepage_more_info_btn} <LinkArr/>
                                </Link>
                        </div>,
                        key: item?.id,
                        children: <div className={'img-wrapper'}>
                            <span className={'img'}>
                                <img src={generateImageMediaUrl(item?.mediaHome?.path)}
                                     title={item?.mediaHome?.title ? item?.mediaHome?.title : ''}
                                     alt={item?.mediaHome?.altAttribute ? item?.mediaHome?.altAttribute : ''}
                                     className="about-img"/>
                            </span>
                        </div> ,
                    };
                })}
            />
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'services',
    ])
};

export default connect(mapStateToProps)(Project);
