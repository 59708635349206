// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import Components;
import Partners from "../components/Partners";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import MainBlock from "../components/homepage/MainBlock";
import Project from "../components/homepage/Project";
import Subscribe from "../components/Subscribe";
import HomepageNews from "../components/homepage/HomepageNews";
import Sponsors from "../components/Sponsors";
import Maintenance from "../components/homepage/Maintenance";


class Homepage extends Component {
    render() {
        const {partners, staticTexts,contacts,projects,requestLoading,sponsors} = this.props;

        return <div className="homepage-wrapper">
            <MainBlock staticTexts={staticTexts} contacts={contacts}/>
            <Partners partners={partners} staticTexts={staticTexts}/>
            <Project projects={projects} staticTexts={staticTexts}/>
            <HomepageNews/>
            <Sponsors sponsors={sponsors} staticTexts={staticTexts}/>
            <Maintenance staticTexts={staticTexts}/>
            <Subscribe requestLoading={requestLoading} staticTexts={staticTexts}/>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'partners',
        'requestLoading',
        'staticTexts',
        'contacts',
        'projects',
        'sponsors',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
