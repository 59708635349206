import React from "react";

import LinkButton from "../uiElements/LinkButton/LinkButton";
import { generateImageMediaUrl } from "../../utils/generateMediaUrl";

import "../../assets/styles/containers/servicespage/serviceHeader.scss";

export default function ServiceHeader({ selectedService,staticTexts}) {
  return (
    <div className="service-header">
      <div className="service-header-info">
        {/*<p className="service-header-info-name">{selectedService?.name}</p>*/}
        <h2 className="service-header-info-title">{selectedService?.subTitle}</h2>
        <h4 className="service-header-info-description">{selectedService?.subDescription}</h4>
        <LinkButton text={`${staticTexts?.homepage_main_block_apply}`} link="contacts" />
      </div>
        <div className={'service-header-img'}>
            <div className={'header-img'}>
                <img src={generateImageMediaUrl(selectedService?.mediaMain?.path)} alt=""/>
            </div>
        </div>
    </div>
  );
}
