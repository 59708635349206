import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { getPropsFromState } from "../redux/mapStateToProps";
import { GetAbout } from "../redux/actions";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";

import "../assets/styles/containers/about.scss"
import Subscribe from "../components/Subscribe";

function About(props) {
  const { about, GetAbout, staticTexts } = props;
  const { pathname } = useLocation();
  const lang = pathname.split("/")[1];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    GetAbout();
  }, [lang,props.selectedLanguage?.code]);

  return (
    <div className="about_page-wrapper">
      <div className="about-header">
        <p className="about-header-name">{staticTexts?.about_us_title}</p>
        <h2 className="about-header-title">{about?.title}</h2>
        <h4 className="about-header-description">{about?.description}</h4>
      </div>
      <div className="about-main">
        <div className="about-main-info">
          <p className="about-main-info-text">{about?.subDescription}</p>
        </div>
        <div className="about-main-img">
          <div className="about-main-img-resonsive">
            <img
              src={generateImageMediaUrl(about?.mediaMain?.path)}
              alt={about?.mediaMain?.name}
              title={about?.mediaMain?.name}
            />
          </div>
        </div>
      </div>
      <div className="about-information">
        {about?.blocks?.map((block, index) => {
          return (
            <div key={index} className="about-information-image-block">
              <img
                src={generateImageMediaUrl(block?.mediaMain?.path)}
                alt={block?.mediaMain?.name}
                title={block?.mediaMain?.name}
              />
              <h3 className="about-information-image-block-title">{block?.title}</h3>
              <h5 className="about-information-image-block-description">{block?.description}</h5>
            </div>
          );
        })}
      </div>
      <Subscribe staticTexts={staticTexts}/>
    </div>
  );
}

const mapStateToProps = (state) => {
  return getPropsFromState(state, ["about", "staticTexts"]);
};

const mapDispatchToProps = {
  GetAbout,
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
