export  const  defaultTexts={
    title:'Dank Systems',
    description:"Dank Systems",
}

export const REQUEST_TYPE = {
    success: "success",
    error: "error",
    title: "title",
}
