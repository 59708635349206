// Import packages
import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

//Import styles
import "../assets/styles/containers/layout.scss";

// Import components
import Header from "../components/Header";
import Footer from "../components/Footer";
import RightMenu from "../components/RightMenu";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetLanguages,
    GetContacts,
    GetHome,
    GetPartners,
    GetStaticTexts, GetServices, GetSponsors, GetBlogs,
} from "../redux/actions";

class Layout extends Component {
    constructor() {
        super();
        this.state = {
            mobileMenuIsOpen: false,
            changeLanguage:false,
        };
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.toggleLanguage=this.toggleLanguage.bind(this);
    }

    async componentDidMount() {
       await this.props.GetLanguages().finally(() => {
            this.props.GetStaticTexts();
        });
        this.props.GetPartners();
        this.props.GetSponsors();
        this.props.GetContacts();
        this.props.GetServices()
        this.props.GetHome()
        this.props.GetBlogs()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, selectedLanguage} = this.props;
        if (location?.pathname !== prevProps?.location?.pathname) {
            window.scrollTo(0, 0);
            this.setState({
                mobileMenuIsOpen: false,
            })
        }

        if (selectedLanguage && prevProps.selectedLanguage && selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetPartners();
            this.props.GetSponsors();
            this.props.GetStaticTexts();
            this.props.GetContacts();
            this.props.GetServices();
            this.props.GetHome();
            this.props.GetBlogs();
        }
    }

    toggleMobileMenu() {
        this.setState({
            mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
        })
    }
    toggleLanguage() {
        this.setState({
            changeLanguage: !this.state.changeLanguage,
        })
    }

    render() {
        const {staticTexts, children, categories, menuList} = this.props;
        const {mobileMenuIsOpen} = this.state;

        return <div className="layout-wrapper">
            {/*<MetaTags/>*/}
            <Header
                mobileMenuIsOpen={mobileMenuIsOpen}
                categories={categories}
                toggleMobileMenu={this.toggleMobileMenu}
                changeLanguage={this.state.changeLanguage}
                toggleLanguage={this.toggleLanguage}
                staticTexts={staticTexts}
            />
            <div className={`main-content`}>
                {children}
            </div>
            <Footer/>
            <RightMenu
                menuList={menuList}
                toggleMobileMenu={this.toggleMobileMenu}
                changeLanguage={this.state.changeLanguage}
                toggleLanguage={this.toggleLanguage}
                categories={categories}
                className={mobileMenuIsOpen ? 'expand_on' : ''}
            />
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'selectedLanguage',
        'categories',
        'staticTexts',
        'menuList',
        'blogs'
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetHome,
    GetBlogs,
    GetPartners,
    GetSponsors,
    GetContacts,
    GetStaticTexts,
    GetServices,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
